import { BundleCardType } from '../components/bundleCard/BundleCardConstants';
import { BundlePackage } from '../models/bundles/Bundles';
import { mockDataServices } from '../mock/BundleMock';

export const getOffersForPostcode = (
  postcode: string,
  bundles: BundlePackage[],
): BundlePackage[] => {
  // return bundles;
  return bundles.filter((b) => b.postcodes.includes(postcode));
};

export const getServicesForPostcode = (
  postcode: string,
  services: BundleCardType[],
): BundleCardType[] => {
  // return services;
  return services.filter((s) => s.postcodes.includes(postcode));
};

export const isAvailable = (
  postcode: string,
  bundle: BundlePackage | undefined,
  location: string,
): boolean => {
  // Reenabling postcode check

  if (location.includes('electricianQuote')) {
    return mockDataServices[0].postcodes.includes(postcode);
  } else {
    if (bundle === undefined) return false;
    return bundle.postcodes.includes(postcode);
  }
};
